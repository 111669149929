import {
  getNotificationSettings,
  updateNotificationSettings,
} from '@wix/ambassador-social-groups-v1-notification-settings/http';
import {
  NotificationChannel,
  NotificationSettings,
  NotificationType,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';
import { BaseApi } from '@wix/social-groups-api';
import { NotificationSettingsMap } from './types';

export class NotificationsApi extends BaseApi {
  async getNotificationSettingsMap(
    groupId: string,
    channel: NotificationChannel,
  ): Promise<NotificationSettingsMap> {
    const {
      data: { settings: channelSettings },
    } = await this.getHTTPClient().request(
      getNotificationSettings({
        groupId,
        channel,
      }),
    );
    return (channelSettings || []).reduce((acc, settings) => {
      if (settings.notificationType) {
        acc[settings.notificationType] = !!settings.mute;
      }
      return acc;
    }, {} as NotificationSettingsMap);
  }

  updateNotificationSettings(
    groupId: string,
    channel: NotificationChannel,
    settingsMap: NotificationSettingsMap,
  ) {
    const settings: NotificationSettings[] = Object.keys(settingsMap).map(
      (notificationType) => {
        return {
          notificationType: notificationType as NotificationType,
          mute: settingsMap[notificationType as NotificationType],
        };
      },
    );

    return this.getHTTPClient().request(
      updateNotificationSettings({
        groupId,
        channel,
        settings,
      }),
    );
  }
}
